import { useEffect, useState } from 'react'
import type { NextPage } from 'next'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'

import type { Curriculum, Standard } from '@domain/index'
import { css } from '@emotion/react'
import useSessionUser from '@hooks/useSessionUser'
import useSnackbar from '@hooks/useSnackbar'
import { standardMocks } from '@mocks/sections.mock'
import { Add, Button } from '@motrix/core'
import { colors } from '@motrix/tokens'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Skeleton from '@mui/material/Skeleton'
import curriculumService from '@services/curriculum/curriculum.service'
import CurriculumCard from '@ui/CurriculumCard'
import CurriculumSectionHeader from '@ui/CurriculumSectionHeader'
import Navbar from '@ui/Navbar'
import NoResultsCard from '@ui/NoResultsCard'
import SkeletonList from '@ui/SkeletonList'

const CreateCurriculumDrawer = dynamic(
  () => import('@components/CreateCurriculumDrawer/CreateCurriculumDrawer'),
)

const CurriculumListPage: NextPage = () => {
  const user = useSessionUser()
  const { openSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(true)
  const [curriculums, setCurriculums] = useState<Array<Curriculum>>([])
  const [_, setStandards] = useState<Array<Standard>>([])

  const [isCreateCurriculumDrawerOpen, setIsCreateCurriculumDrawerOpen] =
    useState(false)

  useEffect(() => {
    const fetchCurriculums = async () => {
      const result = await curriculumService.getAll()

      if (result.isErr) {
        setIsLoading(false)
        openSnackbar({
          message: result.error.message,
          severity: 'error',
        })

        return
      }

      setCurriculums(result.value.curriculums)
      setIsLoading(false)
    }

    fetchCurriculums()
    setStandards(standardMocks)
  }, [openSnackbar])

  const renderCurriculum = (curriculum: Curriculum) => {
    const {
      description,
      grades,
      id,
      microSkillsCount,
      title,
      standards,
      subjects,
    } = curriculum

    return (
      <Box key={id} css={curriculumWrapperStyles} sx={{ cursor: 'pointer' }}>
        <Link legacyBehavior href={`/curriculums/${id}`} passHref>
          <a>
            <CurriculumCard
              description={description}
              grades={grades}
              microSkillsCount={microSkillsCount}
              title={title}
              standards={standards}
              subjects={subjects}
              warningCount={0}
            />
          </a>
        </Link>
      </Box>
    )
  }

  const renderListLoader = () => {
    return (
      <Container maxWidth="md">
        <Box display="flex" justifyContent="space-between">
          <Skeleton animation="wave" height={30} variant="text" width={200} />
          <Skeleton animation="wave" height={30} variant="text" width={200} />
        </Box>
        <SkeletonList
          animation="wave"
          height={120}
          variant="text"
          width="100%"
        />
      </Container>
    )
  }

  const isEmpty = curriculums?.length <= 0
  const noResults = !isLoading && isEmpty
  const hasCurriculums = !isLoading && !isEmpty

  const sectionTitle = `${curriculums?.length ?? 0} currículos`

  const navBarTitleName = `Currículos`

  return (
    <>
      <Head>
        <title>Motrix - Curriculum</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <Navbar.Wrapper>
        <Navbar.Title isLoading={isLoading} name={navBarTitleName} />
      </Navbar.Wrapper>

      <CreateCurriculumDrawer
        isOpen={isCreateCurriculumDrawerOpen}
        onDismiss={() => setIsCreateCurriculumDrawerOpen(false)}
      />

      <main css={mainStyles}>
        {isLoading && renderListLoader()}

        {noResults && (
          <Box css={noResultsCardWrapperStyles}>
            <NoResultsCard
              buttonLabel="Novo currículo"
              subTitle="Crie currículos de forma eficiente através da seleção de micro-habilidades presentes em diferentes orientações curriculares."
              image={
                <Image alt="" src="/icons/news.svg" height={104} width={150} />
              }
              onButtonClick={() => setIsCreateCurriculumDrawerOpen(true)}
              title="Comece a criar os seus Currículos"
            />
          </Box>
        )}

        {hasCurriculums && (
          <Container maxWidth="md">
            <Box component="section" css={curriculumSectionWrapperStyles}>
              <Box css={wrapperStyles}>
                <CurriculumSectionHeader
                  curriculums={curriculums}
                  title={sectionTitle}
                />
                <Button
                  onClick={() => setIsCreateCurriculumDrawerOpen(true)}
                  rightIcon={<Add color={colors.neutral00} />}
                  data-testid="create-curriculum-btn"
                >
                  Criar currículo
                </Button>
              </Box>

              {curriculums?.map(renderCurriculum)}
            </Box>
          </Container>
        )}
      </main>
    </>
  )
}

const mainStyles = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-block-start: 30px;
`

const noResultsCardWrapperStyles = css`
  margin-block-start: 90px;
  width: 366px;
`

const curriculumSectionWrapperStyles = css`
  width: 100%;
`

const curriculumWrapperStyles = css`
  margin-block-end: 14px;
`

const wrapperStyles = css`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-block-end: 25px;
`

export default CurriculumListPage
